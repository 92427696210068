<template>
  <div class="row">
    <div class="col-lg-9">
      <div class="loader-block">
        <div v-if="Object.keys(contracts).length">
          <div class="row">
            <div v-for="contract in contracts" :key="contract" class="col-lg-6">
              <div class="widget">
                <div class="widget-heading flex-column flex-md-row">
                  <h6 class="textStyle">
                    {{ contract.contractNumber
                    }}{{
                      contract.contractType
                        ? " - " + contract.contractType.title
                        : ""
                    }}
                  </h6>
                  <div class="mt-2 mt-md-0">
                    <router-link
                      :to="{
                        name: 'studeaManagerContractEdit',
                        params: { id: contract.id, alias: 'contract' },
                      }"
                      class="list__button"
                      v-tooltip="{
                        title: $tc('global.edit.title', 1, {
                          name: this.$tc(
                            'studea_manager.contract.title',
                            1,
                          ).toLowerCase(),
                        }),
                      }"
                      v-access:[$store.state.user.permissions]="
                        accessManager().EDIT
                      "
                    >
                      <FontAwesomeIcon icon="fa-light fa-edit" />
                    </router-link>
                    <button
                      class="list__button btn-icon ms-2"
                      @click="remove(contract.id)"
                      v-access:[$store.state.user.permissions]="
                        accessManager().DELETE
                      "
                    >
                      <FontAwesomeIcon icon="fa-light fa-trash" />
                    </button>
                  </div>
                </div>
                <div class="widget-content">
                  <div>
                    <span class="fw-bold textStyle">{{ $t("company") }}:</span>
                    {{ contract.company ? contract.company.companyName : "" }}
                  </div>
                  <div class="mt-1">
                    <span class="fw-bold textStyle">{{
                      $t("studea_manager.contract.address")
                    }}</span
                    ><br />
                    {{
                      contract.company && contract.company.address1
                        ? contract.company.address1
                        : ""
                    }}
                    {{ contract.company && contract.company.address2 ? '<br />'
                    + contract.company.address2 : '' }}
                    <br />
                    {{
                      contract.company && contract.company.postCode
                        ? contract.company.postCode
                        : ""
                    }}
                    {{
                      contract.company && contract.company.city
                        ? contract.company.city
                        : ""
                    }}
                  </div>
                  <div
                    v-if="contract.startDate && contract.endDate"
                    class="mt-1"
                  >
                    <span class="fw-bold textStyle">{{
                      $t("studea_manager.contract.dates")
                    }}</span>
                    : {{ format(contract.startDate) }} -
                    {{ format(contract.endDate) }}
                  </div>
                  <div v-if="contract.duration" class="mt-1">
                    <span class="fw-bold textStyle">{{
                      $t("studea_manager.contract.duration")
                    }}</span>
                    : {{ contract.duration }}
                    {{ $t("studea_manager.contract.months") }}
                  </div>
                  <div v-if="contract.apprenticeMaster" class="mt-1">
                    <span class="fw-bold textStyle">{{
                      $t("studea_manager.contract.map")
                    }}</span>
                    :
                    {{
                      contract.apprenticeMaster.firstName +
                      " " +
                      contract.apprenticeMaster.lastName
                    }}
                  </div>
                  <div v-if="contract.manager" class="mt-1">
                    <span class="fw-bold textStyle">{{
                      $t("studea_manager.contract.manager")
                    }}</span>
                    :
                    {{
                      contract.manager.firstName +
                      " " +
                      contract.manager.lastName
                    }}
                  </div>
                  <div v-if="contract.ruptureDate" class="mt-1">
                    <span class="fw-bold textStyle">{{
                      $t("studea_manager.contract.rupture_date")
                    }}</span>
                    :
                    {{ format(contract.ruptureDate) }}
                  </div>
                  <div v-if="contract.ruptureCause" class="mt-1">
                    <span class="fw-bold textStyle">{{
                      $t("studea_manager.contract.rupture_reason")
                    }}</span>
                    :
                    {{ contract.ruptureCause }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="widget p-3">
          {{ $t("studea_manager.contract.empty") }}
        </div>
        <BlockLoader :loading="loading" />
      </div>
    </div>
    <div class="col-lg-3">
      <div class="sticky">
        <div id="action-blocks">
          <ActionsBlock
            :actions="actions"
            :collapsable="false"
            :title="$t('global.actions.title')"
            collapseId="actions"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionsBlock from "@/components/blocks/ActionsBlock.vue";
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { dateFormatter } from "@/services/utilities/date-formatter";
import { accessManager } from "@/services/security/access-manager";

export default {
  name: "ContractListView",
  components: {
    FontAwesomeIcon,
    BlockLoader,
    ActionsBlock,
  },
  data() {
    return {
      actions: [
        {
          translation: "global.add.title",
          translationGender: 1,
          translationParams: {
            name: this.$tc("studea_manager.contract.title", 1).toLowerCase(),
          },
          icon: "plus",
          type: "success",
          actionType: "router",
          action: "studeaManagerContractAdd",
          cfaStyle: false,
          params: { alias: "contract" },
          access: accessManager.EDIT,
        },
      ],
      contracts: [],
      loading: false,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    accessManager() {
      return accessManager;
    },
    init() {
      this.loading = true;
      studeaManagerManager
        .list(
          "contract",
          this.$route.params.trainingCourseId,
          false,
          [],
          this.$route.params.triadId,
        )
        .then((res) => {
          this.contracts = res;
          this.loading = false;
        });
    },

    remove(id) {
      studeaManagerManager
        .remove("contract", this.$route.params.trainingCourseId, id)
        .then(() => {
          this.init();
        });
    },

    format(date) {
      return dateFormatter.format(date, "short");
    },
  },
};
</script>

<style lang="scss" scoped></style>

import axios from "axios";
import router from "@/router";
import store from "@/store";
import { loginManager } from "@/services/security/login-manager";
import { platformManager } from "@/services/platform/platform-manager";

class ApiConnection {
  token = "";
  calling = null;
  callingMethod = null;

  constructor() {
    loginManager.reloadToken();
  }

  get(path, params = {}, security = true) {
    const urlParams = new URLSearchParams(params);
    var url = loginManager.apiPath + "/" + store.state.theme.locale + path;
    if (Object.keys(params).length) {
      url += "?" + urlParams.toString();
    }
    return this.fetchApi("get", url, null, security);
  }

  post(path, params = {}, security = true) {
    const url = loginManager.apiPath + "/" + store.state.theme.locale + path;
    return this.fetchApi("post", url, params, security);
  }

  put(path, params = {}, security = true) {
    const url = loginManager.apiPath + "/" + store.state.theme.locale + path;
    return this.fetchApi("put", url, params, security);
  }

  delete(path, params = {}, security = true) {
    const url = loginManager.apiPath + "/" + store.state.theme.locale + path;
    return this.fetchApi("delete", url, params, security);
  }

  fetchApi(method, path, params, security = true, platformFetch = false) {
    if (!loginManager.getApiPath() && !loginManager.apiPath && !platformFetch) {
      return platformManager.loadPlatform().then((platform) => {
        if (platform.hostname && platform.name) {
          loginManager.setApiPath(platform.hostname);
          store.commit("SET_PLATFORM_NAME", platform.name);
          path = loginManager.apiPath + path;
          this.fetchApi(method, path, params, security);
        }
      });
    } else {
      const headers = {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "",
        "Access-Control-Allow-Origin": "*",
        Cookie: "XDEBUG_SESSION=PHPSTORM",
      };
      if (security) {
        headers.Authorization = "Bearer " + loginManager.token;
      }

      if (
        this.calling &&
        this.calling === path.split("?")[0] &&
        this.callingMethod &&
        this.callingMethod === method
      ) {
        this.source.cancel();
      }

      this.calling = path.split("?")[0];
      this.callingMethod = method;
      const cancelToken = axios.CancelToken;
      this.source = cancelToken.source();

      const call = axios({
        method: method,
        url: path,
        data: params,
        headers: headers,
        origin: "*",
        withCredentials: true,
        cancelToken: this.source.token,
      });

      return new Promise((resolve, reject) => {
        call
          .then((response) => {
            store.commit("SET_INTERNET", true);
            this.calling = null;
            this.callingMethod = null;
            store.commit("SET_ERROR", false);
            resolve(response.data);
          })
          .catch((error) => {
            if (error.code === "ERR_NETWORK") {
              store.commit("SET_INTERNET", false);
            } else {
              store.commit("SET_INTERNET", true);
              const responseObject = error.response
                ? error.response.data
                : null;
              if (responseObject && responseObject.code === 401) {
                store.commit("SET_INTERNET", true);
                if (responseObject.message === "Expired JWT Token") {
                  loginManager
                    .refreshConnection()
                    .then((success) => {
                      if (success.success && success.token) {
                        loginManager.token = success.token;
                      }
                      this.fetchApi(method, path, params, security).then(() => {
                        router.push({
                          name: router.currentRoute.value.name,
                          params: router.currentRoute.value.params,
                          replace: true,
                        });
                      });
                    })
                    .catch(() => {
                      router.push("/login");
                    });
                } else if (responseObject.message === "Bad credentials") {
                  reject(responseObject);
                } else {
                  router.push("/login");
                }
              } else if (responseObject && responseObject.status > 401) {
                store.commit("SET_ERROR_CODE", responseObject.status);
                store.commit("SET_MESSAGE", responseObject.detail);
                store.commit("SET_ERROR", true);
              }
            }
            this.calling = null;
            this.callingMethod = null;
          });
      });
    }
  }
}

export const apiConnection = new ApiConnection();
